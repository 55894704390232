import { useEffect, useRef, useState } from "react";
import { WHEEL_LUOTQUAY, WHEEL_PHANTHUONG } from "../../utils/KeyConstant";
import Image_GIFT from "../../assets/fontawesome/image/popup-gift-ani.png";
import { useNavigate, useParams } from "react-router";
import { ocrServices } from "../../services/apiService/ocrServices";
import { format } from "date-fns";
import uuid from "react-uuid";
import html2canvas from "html2canvas";
import { handleChangeImage } from "../../utils/compressImage";
import { homeServices } from "../../services/apiService/homeServices";
import { userServices } from "../../services/apiService/userServices";
import ImageWithTimestamp from "./ImageWithTimeStamp";

export default function ContentGiftPrize() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const dataCallUrbox = JSON.parse(localStorage.getItem(WHEEL_LUOTQUAY));
  const winningGift = JSON.parse(
    localStorage.getItem(WHEEL_PHANTHUONG) || "{}"
  );
  const [back, setBack] = useState("");

  useEffect(() => {
    imageUrlToBase64(winningGift?.gift_image)
      .then((res) => {
        console.log(res);
        setGift(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [winningGift]);
  const [gift, setGift] = useState();
  const [checkLast, setCheckLast] = useState(false);
  let [ocrEndpoint, setOcrEndpoint] = useState(null);
  const [checkPer, setChecPer] = useState(false);
  const ref = useRef(null);
  const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };
  const handleChangeAnnounceStatus = async () => {
    if (winningGift.gift_code.includes("biggift")) {
      setChecPer(true);
      console.log(1);
      setBack("wheel");

      uploadImageScreeshot(false);
      setCheckLast(false);
    } else {
      console.log(2);

      navigation(`/wheel/${id}`);
    }
  };
  const getHomepageBanner = () => {
    homeServices
      .getRunningCampaignTopAndDown()
      .then((res) => {
        setOcrEndpoint(res.ocr_endpoint);
      })
      .catch((err) => {});
  };
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const handleNavigateSucess = () => {
    const params = {
      so_id: id,
    };
    userServices
      .postUpdateUrbox(params)
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNavigateRedirectLastGift = () => {
    if (winningGift?.gift_code?.includes("biggift")) {
      setChecPer(true);

      setCheckLast(true);
      setBack("list-gift");
      uploadImageScreeshot(true);
    } else {
      navigation(`/list-gift`);
    }
  };
  const [loadTimeStamp, setLoadTimeStamp] = useState(false);
  const [imageDataCanvas, setImageDataCanvas] = useState();
  const uploadImageScreeshot = (check) => {
    const a = JSON.parse(localStorage.getItem("IMAGE_BIGGIFT")) || [];
    if (ref.current) {
      html2canvas(ref.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        setImageDataCanvas(imgData);
      });
    }
  };
  const voucherLine = localStorage.getItem("VOUCHER_LINE");
  const voucherLineWheel = localStorage.getItem("VOUCHER_LINE_WHEEL");
  useEffect(() => {
    getHomepageBanner();
    if (voucherLine !== voucherLineWheel) {
      navigation("/list-rotation");
    }
    if (dataCallUrbox < 1) {
      setLoading(true);
      handleNavigateSucess();
    }

    disableButtonBack();
  }, []);

  const disableButtonBack = () => {
    window.history.pushState(null, null, window.location.href);
    console.log(window.location.href);
    window.history.back();
    window.onpopstate = function () {
      window.history.forward();
      window.history.pushState(null, null, window.location.href);
    };
    console.log("disable");
  };

  const date = new Date();
  console.log(formatDate(date));
  function formatDate(date) {
    let datePart = [date.getMonth() + 1, date.getDate(), date.getFullYear()]
      .map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0"))
      .join("/");
    let timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
      .map((n, i) => n.toString().padStart(2, "0"))
      .join(":");
    return datePart + " " + timePart;
  }
  return (
    <div className="z-50">
      <div
        className="fixed z-50 overflow-y-auto top-0 w-full left-0 show"
        id="modal"
      >
        <div
          className="flex items-center justify-center min-height-100vh
         px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            className="fixed inset-0 transition-opacity bg-wheel-fake"
            ref={ref}
          >
            <div className="absolute inset-0 bg-gray-900 opacity-70" />
            {/* {loadTimeStamp ? (
              <div className="absolute left-2 text-red-600 font-bold-mon">
                {formatDate(date)}
              </div>
            ) : null} */}
            <div className="content-popup-condition w-full">
              <div className="popup-otp relative top-10 w-full z-50">
                <div className=" flex justify-center">
                  <div
                    className="containerNotify__background-tc absolute w-9/12 rounded-3xl 
                 z-50 bg-white py-2 border-notify top-[170px] max-[390px]:h-[58%] max-[415px]:h-[60%] h-[66%]"
                  >
                    <p className="containerNotify__background-list">
                      {/* <canvas id="canvas" width="180" height="180"></canvas> */}
                      {checkPer ? (
                        <ImageWithTimestamp
                          back={back}
                          base64Image={imageDataCanvas}
                          checkLast={checkLast}
                          id={id}
                          setLoading={setLoading}
                        ></ImageWithTimestamp>
                      ) : null}

                      <div className="text-center">
                        {winningGift?.gift_code?.includes("cbmm") ? (
                          <h1 className="uppercase my-[5px] leading-8 font-bold-mon text-[24px] text-[#003DA5]">
                            chúc bạn may
                            <br /> mắn lần sau
                          </h1>
                        ) : (
                          <h1 className="uppercase my-[5px] leading-8 font-bold-mon text-[24px] text-[#003DA5]">
                            chúc mừng bạn
                            <br /> đã trúng
                          </h1>
                        )}
                      </div>
                      <div className="">
                        {winningGift?.gift_code?.includes("cbmm") ? null : (
                          <div>
                            <div className="text-[20px] px-5 font-bold-mon">
                              {winningGift?.gift}
                            </div>
                            <div className="text-[12px] font-regular-mon">
                              {winningGift?.voucher_line_id}
                            </div>
                          </div>
                        )}
                        <img
                          className="relative left-1/2 -translate-x-1/2 w-7/12"
                          src={gift}
                          alt=""
                          id="imageid"
                          crossOrigin="anonymous"
                        />
                      </div>
                      <p className="block px-3 text-justify font-regular-mon mt-3">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: winningGift?.conditions_receiving_prize,
                          }}
                          className="dont-break-out"
                        ></div>
                      </p>
                    </p>
                  </div>
                </div>
                <div
                  className={`relative w-[99%] left-1/2 -translate-x-1/2 top-10
                        `}
                >
                  <div className="relative">
                    <>
                      <div className="absolute -top-[170px] background-light-ani"></div>
                      <div className="absolute -top-36 background-firework-ani"></div>
                    </>
                    <img
                      src={Image_GIFT}
                      className="w-10/12 relative left-1/2 -translate-x-1/2"
                    />
                  </div>
                  <div className="absolute left-1/2 -translate-x-1/2 w-11/12 max-[376px]:bottom-9 max-[415px]:bottom-11 bottom-5 px-8">
                    <>
                      {loading ? (
                        <button
                          className="border_btn-agree text-white text-[16px] bg-[#2B67BE] px-4 py-1
                        rounded-xl font-semibold-mon"
                        >
                          <div className=" font-bold-mon text-[#ffffff] px-[25px] py-[10px] rounded-xl text-[16px] leading-5">
                            <div className="w-20 flex justify-center">
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="border_btn-agree text-white text-[16px] bg-[#2B67BE] 
                          rounded-xl h-16 px-4 font-semibold-mon "
                          defaultValue="Đồng ý"
                          onClick={() => {
                            setBack("wheel");
                            // localStorage.removeItem(WHEEL_PHANTHUONG);
                            setLoading(true);
                            setLoadTimeStamp(true);
                            if (dataCallUrbox >= 1) {
                              handleChangeAnnounceStatus();
                            } else {
                              handleNavigateRedirectLastGift();
                            }
                          }}
                        >
                          {dataCallUrbox >= 1 ? (
                            <>
                              Quay tiếp
                              <br /> (còn {dataCallUrbox} lượt quay)
                            </>
                          ) : (
                            <>Nhận quà ngay</>
                          )}
                        </button>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
