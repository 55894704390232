import BG_LIGHT_CODE from "../../assets/fontawesome/image/bg-ligh-code.png";
import BT_LEFT from "../../assets/fontawesome/image/bt-left.png";
import BT_LEFT_DISABLE from "../../assets/fontawesome/image/bt-left-dis.png";
import BT_RIGHT from "../../assets/fontawesome/image/bt-right.png";
import POPUP_CARD_TOP from "../../assets/fontawesome/image/popup-card-top.png";
import POPUP_CARD_BOTTOM from "../../assets/fontawesome/image/popup-card-bottom.png";
import GIFT_TEST from "../../assets/fontawesome/image/gift-test.png";
import GIFT_CODE from "../../assets/fontawesome/image/code.png";
import { WHEEL_LUOTQUAY, WHEEL_PHANTHUONG } from "../../utils/KeyConstant";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useRef, useState } from "react";
import ImageWithTimestamp from "../../component/ConfirmPopupTnC/ImageWithTimeStamp";
import html2canvas from "html2canvas";
import { userServices } from "../../services/apiService/userServices";

export default function PageReceiveGiftCode() {
  const navigation = useNavigate();
  const { id } = useParams();

  const data = JSON.parse(localStorage.getItem(WHEEL_PHANTHUONG));
  const dataLuotQuay = JSON.parse(localStorage.getItem(WHEEL_LUOTQUAY));
  let appCode = window.location.pathname.split("/")[1];
  let giftCode = window.location.pathname.split("/")[2];
  console.log(data?.gift_code.includes("cbmm"));
  const [back, setBack] = useState("");
  const voucher = localStorage.getItem("VOUCHER_LINE_Gill") || "";
  console.log(voucher);
  const handleBack = () => {
    setLoading(true);
    setBack("spin-freefire");
    // localStorage.removeItem(WHEEL_PHANTHUONG);
    if (dataLuotQuay === 0) {
      if (data?.gift_code?.includes("biggift")) {
        uploadImageScreeshot(false);
      } else {
        navigation(`/list-gift`);
      }
    } else {
      setLoading(false);
      if (data?.gift_code?.includes("biggift")) {
        uploadImageScreeshot(false);
      } else {
        navigation(`/spin-freefire/${giftCode}`);
      }
    }
  };
  const handleMyGift = () => {
    setLoading(true);

    if (data?.gift_code?.includes("biggift")) {
      setCheckLast(true);
      uploadImageScreeshot(false);
    } else {
      navigation(`/list-gift`);
      localStorage.removeItem(WHEEL_PHANTHUONG);
    }
  };
  const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };
  const [gift, setGift] = useState();

  useEffect(() => {
    imageUrlToBase64(data?.gift_image)
      .then((res) => {
        setGift(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data]);
  const voucherLine = localStorage.getItem("VOUCHER_LINE_Gill");
  const voucherLineWheel = localStorage.getItem("VOUCHER_LINE_GiftGillet");
  useEffect(() => {
    if (voucherLine !== voucherLineWheel) {
      navigation("/list-rotation");
    }
    if (dataLuotQuay === 0) {
      setCheckLast(true);
      handleNavigateSucess();
    }
  }, []);
  const handleNavigateSucess = () => {
    const params = {
      so_id: id,
    };
    userServices
      .postUpdateUrbox(params)
      .then((res) => {
        setCheckLast(false);
        console.log(res);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ref = useRef();
  const [checkLast, setCheckLast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageDataCanvas, setImageDataCanvas] = useState();
  const handleChangeAnnounceStatus = async () => {
    // navigation(`/wheel/${id}`);
  };
  const uploadImageScreeshot = (check) => {
    const a = JSON.parse(localStorage.getItem("IMAGE_BIGGIFT")) || [];
    if (ref.current) {
      html2canvas(ref.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        console.log(imgData);
        setImageDataCanvas(imgData);
        // const canvas1 = document.getElementById("canvas");
        // const ctx = canvas1.getContext("2d");
        // let img = new Image(imgData);

        // img.addEventListener("load", () => {
        //   ctx.drawImage(img, 0, 0);
        //   ctx.font = "50px serif";
        //   ctx.fillText("Hello world", 50, 90);
        // });
        // console.log(img);
      });
    }
  };
  return (
    <div className="relative top-0 bg-black min-h-full" ref={ref}>
      <img src={BG_LIGHT_CODE} className="animation-bg-code relative top-0" />
      <div className="absolute top-[10%] px-5 text-white">
        <div className="relative">
          <div className="relative">
            <ImageWithTimestamp
              back={back}
              base64Image={imageDataCanvas}
              checkLast={checkLast}
              id={id}
              setLoading={setLoading}
            ></ImageWithTimestamp>
            <div className="relative top-0">
              <img src={POPUP_CARD_TOP} />
              {data?.gift_code.includes("cbmm") ? null : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.conditions_receiving_prize,
                  }}
                  className="absolute bottom-10 text-justify text-[12px] px-10 w-full"
                ></div>
              )}
            </div>
            <div className="absolute top-10 w-full">
              <div className="relative text-center">
                {data?.gift_code.includes("cbmm") ? null : (
                  <div>
                    <div className="text-[#FFED00] font-bold-mon text-[32px]">
                      CHÚC MỪNG
                    </div>
                    <div className="text-[14px]">Bạn đã trúng</div>
                  </div>
                )}

                <div className="font-bold-mon text-[#FFED00] mt-5">
                  {data?.gift}
                </div>
                {data?.gift_code.includes("cbmm") ? null : (
                  <div className="text-[12px]">{voucher}</div>
                )}
              </div>
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
              <img src={gift} crossOrigin="anonymous" />
              {appCode !== "receive-code" ? (
                <div className="relative top-0 w-full">
                  <div className="absolute top-0">
                    <div className="relative">
                      <img src={GIFT_CODE} />
                      <div className="absolute top-1 left-1/2 -translate-x-1/2 pr-5">
                        {giftCode}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative top-0 w-full">
            <img src={POPUP_CARD_BOTTOM} className="relative top-0" />
            <div className="absolute top-1/2 -translate-y-1/2 left-[5%]">
              <button className="relative font-bold-mon w-10/12 left-1/2 -translate-x-1/2">
                <img
                  src={
                    data?.gift_code.includes("cbmm") ? BT_LEFT_DISABLE : BT_LEFT
                  }
                />
                <div
                  className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[10px] w-full"
                  onClick={handleMyGift}
                >
                  {loading ? (
                    <div className="w-full flex justify-center">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <Fragment>QUÀ CỦA TÔI</Fragment>
                  )}
                </div>
              </button>
            </div>
            <div className="absolute top-1/2 -translate-y-1/2 right-[5%]">
              <button
                className="relative w-10/12 left-1/2 -translate-x-1/2"
                onClick={handleBack}
              >
                <img src={BT_RIGHT} />
                <div className="absolute font-bold-mon top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[10px] w-full">
                  {loading ? (
                    <div className="w-full flex justify-center">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      {dataLuotQuay === 0 ? (
                        <Fragment>
                          BẠN HẾT <br /> LƯỢT QUAY
                        </Fragment>
                      ) : (
                        <Fragment>
                          BẠN CÒN{" "}
                          <span className="text-[12px] text-yellow-300">
                            {dataLuotQuay > 9
                              ? dataLuotQuay
                              : "0" + dataLuotQuay}
                          </span>
                          <br />
                          LƯỢT QUAY
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
